<template>
  <div class="selected-case-new">
    <!-- {{caseList}} -->
    <ul v-if="caseList && caseList.length>0">
      <router-link
        v-for="(item, index) in caseList"
        :key="'selected-case-n-'+index"
        :to="{ path: `/${global.year}/course`, query: { id: item.courseid?item.courseid:item.id } }"
      >
        <div class="content" v-if="item.teacher&&item.teacher[0]">
          <!-- <img :src="JSON.parse(item.source).thumbnailUrl" alt="" /> -->
          <p class="name">{{ item.teacher[0].name }}</p>
          <p class="company">
            {{ checkBlank(item.teacher[0].company) }}<br />{{ checkBlank(item.teacher[0].position) }}
          </p>
        </div>
        <div class="content" v-else-if="item.lecturer&&item.lecturer[0]">
          <img v-if="item.companythumbs" :src="item.companythumbs" alt="" />
          <p class="name">{{ item.lecturer[0].name }}</p>
          <p class="company">
            {{ checkBlank(item.lecturer[0].company) }}<br />{{ checkBlank(item.lecturer[0].position) }}
          </p>
        </div>
        <p class="case-title">
          {{ item.title }}
        </p>
      </router-link>
    </ul>
    <div class="isblank" v-else>{{tip}}</div>
  </div>
</template>

<script>
export default {
  props: {
    caseList: Array,
  },
  data() {
    return {
      tip: "敬请期待",
    };
  },
  mounted() {
    // console.log(this.caseList);
  },
  methods: {
    checkBlank(value) {
      let res = '';
      if(value && value.length>0 && value!=='无') {
        res = value;
      }
      return res;
    },
  },
};
</script>

<style lang="scss">
.selected-case-new {
  width: 100%;
  margin: 0 auto;
  ul {
    width: 100%;
    // max-width: 1227px;
    max-width: 1252px;
    padding-left: 25px;
    margin: 0 auto;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
    justify-content: flex-start;
    a:hover {
      box-shadow: 0px 0px 8px 0px rgba(2, 72, 111, 0.1);
    }
    a {
      display: flex;
      flex-direction: column-reverse;
      margin-right: 25px;
      // margin-right: 24px;
      margin-bottom: 25px;
      padding: 20px 22px 21px 20px;
      text-decoration: none;
      text-align: left;
      background: #ffffff;
      border: 1px solid #d6dce3;
      .case-title {
        width: 305px;
        height: 60px;
        padding-left: 7px;
        padding-bottom: 10px;
        line-height: 25px;
        font-size: 18px;
        font-family: PingFang SC Medium;
        font-weight: 500;
        color: #2630B2;
        margin-bottom: 0;
      }
      .content {
        border-top: 1px solid #d6dce3;
        padding-top: 36px;
        padding-left: 9px;
        width: 340px;
        img {
          width: 69px;
          height: 13px;
        }
        .name {
          font-size: 18px;
          font-family: PingFang SC Bold;
          font-weight: bold;
          color: #484848;
          margin-bottom: 6px;
        }
        .company {
          height: 48px;
          line-height: 16px;
          font-size: 14px;
          font-family: PingFang SC Regular;
          color: #3e3a39;
          margin-bottom: 0;
        }
      }
    }
    a:nth-child(3n) {
      // margin-right: 0;
    }
  }
  .isblank {
    // padding: 30px 0 100px 0;
    padding: 30px 0 0 0;
  }
}
@media (max-width: 1252px) {
  .selected-case-new {
    ul {
      max-width: 843px;
    }
  }
}
// @media (min-width: 750px) and (max-width: 843px) {
//   .selected-case-new {
//     ul {
//       max-width: 434px;
//     }
//   }
// }
// @media (max-width: 1023px) {
// @media (max-width: 767px) {
@media (max-width: 843px) {
  .selected-case-new {
    width: 100vw;
    border: none;
    padding-top: 26px;
    ul {
      width: 100%;
      flex-direction: column;
      padding-left: 7vw;
      padding-right: 7vw;
      padding-bottom: 14px;
      a {
        width: 100%;
        margin-right: 0;
        margin-bottom: 14px;
        padding: 13px;
        .content {
          width: 100%;
          padding-top: 18px;
          img {
            width: 60px;
            height: 12px;
          }
        }
        .case-title {
          width: 100%;
        }
      }
    }
    .isblank {
      padding: 6vh 0 10vh 0;
    }
  }
}

@media (max-width: 750px) {
  .selected-case-new {
    width: 100%;
    padding-top: 0;
    padding-left: 0;
    ul {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 10px;
      a {
        .content{
          p.name {
            font-size: 13px;
          }
          p.company {
                font-size: 13px;
            margin-bottom: 0;
          }
        }
        .case-title {
          font-size: 15px;
        }
      }
    }
    .isblank {
      padding: 0;
    }
  }
}
</style>